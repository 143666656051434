<template>
  <div class="logo-wrap">
    <img
      class="mylogo"
      src="@/assets/images/logo_gqg.png"
      alt
      @click="goHome"
    />
    <span class="system-span" v-if="logoTitle"></span>
    <span class="system-name" v-if="logoTitle">{{ logoTitle }}</span>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: "Logo",
  props: {
    light: {
      type: Boolean,
      default: true
    },
    logoTitle: {
      type: String,
      default: ""
    }
  },
  methods: {
    goHome() {
      this.$router.push("/");
    }
  }
};
</script>
<style lang="scss" scoped>
.logo-wrap {
  display: flex;
  align-items: center;
}
.mylogo {
  width: 330px;
  height: auto;
  cursor: pointer;
  margin-right: 10px;
}
.system-span {
  display: inline-block;
  width: 1px;
  height: 24px;
  background: #cccccc;
}
.system-name {
  display: inline-block;
  font-size: 22px;
  line-height: 22px;
  font-weight: 400;
  margin-left: 12px;
  color: #333333;
}
</style>
