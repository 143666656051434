<template>
  <div class="app-container">
    <div class="app-header-wrap">
      <div class="app-header">
        <div class="header-left">
          <logo></logo>
          <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelectMenu" background-color="#409EFF"
            text-color="#fff" active-text-color="#ffd04b">
            <el-menu-item index="home">个人中心</el-menu-item>
            <el-menu-item index="resume">简历管理</el-menu-item>
            <el-menu-item index="job">职位搜索</el-menu-item>
            <el-menu-item index="chat">在线沟通</el-menu-item>
            <el-menu-item index="favorite">我的收藏</el-menu-item>
            <el-menu-item index="career">职业生涯</el-menu-item>
          </el-menu>
        </div>
        <div class="header-right">
          个人中心
        </div>
      </div>

    </div>
    <div class="app-content-wrap">
      <router-view />
    </div> 
  </div>
</template>

<script>
import Logo from '@/components/Common/Logo'
export default {
  components: { Logo },
  name: 'PersonLayout',
  data() {
    return {
      activeIndex: 'home',
      isLogin: false
    };
  },
  methods: {
    handleSelectMenu(key, keyPath) {
      console.log(key, keyPath);
      this.$router.push(`/person/${key}`);
    },
    handleLogin() {
      this.isLogin = true
    },
    handleResume() {
      this.$router.pusth("/")
    }
  }
}
</script>

<style lang="scss" scoped>
</style>